<template>
    <div class='project-main'>
        <h1 class="header"><span>{{ total }}</span> 人已实名证实</h1>
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="finishedtext"
            @load="onLoad"
        >
            <template v-if="confirmList.length">
                <template v-for="(item, index) in confirmList">
                    <div class="wrap-box">
                        <div class="avatar">
                            <img v-lazy="item.face_url" alt="">
                        </div>
                        <div class="content-box">
                            <div class="top">
                                <span class="name">{{ item.name }}</span>
                                <span class="relat">{{ item.relation | getStatus }}</span>
                                <span class="over">已实名</span>
                            </div>
                            <div class="contain">{{item.content}}</div>
                            <div class="time">{{item.created_at}}</div>
                        </div>
                    </div>
                </template>
            </template>
        </van-list>

    </div>
</template>

<script>
import { List } from 'vant';
import { getProjectDetail } from './api';
import utils from "@/common/js/utils";
const params = utils.getRequestParams();
export default {
    name:'confirm-list',
    data() {
        return {
            confirmList: [],
            total: 0,
            loading: false,
            finished: false,
            finishedtext: '',
            next: ''
        }
    },
    filters: {
        getStatus(e){
            return ['', '亲属', '朋友', '同事', '同学', '邻居', '老师', '学生', '病友', '医护',  '其他'][e]
        }
    },
    components: {
        vanList: List
    },
    mounted() {
        const { project_title } = params;
        document.title = decodeURI(project_title);
    },
    methods: {
        onLoad(){
            const { projuuid } = params;
            const that = this;
            getProjectDetail(projuuid, this.next).then(res => {
                if(res.code === 0) {
                    that.next = res.next
                    if(res.data.length == 0 || res.next == 0) {
                        that.finished = true;
                        that.finishedtext = '没有更多了';
                    }else{
                        that.loading = false;
                        that.confirmList = that.confirmList.concat(res.data);
                        that.total = res.total || 0;
                    }

                }
            })
        }
    }
}
</script>

<style lang='less' scoped>
.project-main {
    padding: 28px 20px;
    font-size: 10px;
    .header {
        margin-bottom: 12px;
        position: relative;
        text-align: left;
        padding-left: 28px;
        &:after {
            content: '';
            display: inline-block;
            width: 16px;
            height: 20px;
            background: url(./images/over.png);
            background-size: 100% 100%;
            position: absolute;
            left: 0;
            top: 4px;
        }
        span {
            color: #0099FF;
        }
    }
    .wrap-box {
        display: flex;
        padding: 20px 0;
        border-bottom: 1px solid #e9e9e9;
        .avatar {
            width: 44px;
            height: 44px;
            flex-shrink: 0;
            margin-right: 16px;
            border-radius: 4px;
            overflow: hidden;
        }
        .content-box {
            .top {
                margin-bottom: 6px;
                text-align: left;
                display: flex;
                align-items: center;
                .name {
                    font-size: 12px;
                    color: #999;
                }
                .relat {
                    display: inline-block;
                    border-radius: 2px;
                    padding: 2px 3px;
                    margin: 0 12px;
                    font-size: 12px;
                    color: #0099FF;
                    line-height: 1;
                    background: #D6F2FF;
                }
                .over {
                    display: inline-block;
                    border-radius: 2px;
                    padding: 2px 3px;
                    font-size: 12px;
                    color: #999;
                    line-height: 1;
                    background-color: #F0F0F0;
                }

            }
            .contain {
                text-align: left;
                font-size: 14px;
                color: #333;
            }
            .time {
                text-align: left;
                font-size: 14px;
                color: #999;
                margin-top: 5px;
            }
        }
    }
}
</style>
