import { mmcAjax } from '@/common/js/mmcModule';
const baseurl = 'https://api.jisuchou.net/';

const getProjectDetail = function(projuuid, next = '') {
    const url = `${baseurl}mmc/project/confirm/list/${projuuid}?next=${next}`;
    const debuger = false;
    if (debuger) {
        const project = require('./mock/project.json');
        return new Promise((resolve, reject) => {
            resolve(project)
        })
    }
    return mmcAjax({
        method: 'GET',
        url,
        crossDomain: true,
    })
}


export {
    getProjectDetail
}